import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { Notification, NotificationLevel, NotificationService } from '@ngx-ivengi/notification';
import { CrudAction, Notification as CrudNotification } from '@ngx-ivengi/crud';
import { ToastrService } from 'ngx-toastr';
import { User } from './components/user/user.model';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { UIRouter } from '@uirouter/angular';
import { whitelabel } from '../whitelabels/whitelabel';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

export interface CustomNotificationRule {
  level?: NotificationLevel;
  action?: CrudAction | CrudAction[];
  entity?: any;
  httpCode?: number;
  errors?: {
    param: string;
    msg: string;
  }[];
  translateKey: string;
}

interface NavInterface {
  name: string;
  id: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})

export class AppComponent implements OnInit, AfterViewInit {
  public cssUrl: SafeResourceUrl;
  public isAuthenticated = false;

  public orgNav: NavInterface[] = null;
  public isSlideshowVisible = false;
  public whitelabel = whitelabel;

  constructor(
    private sanitizer: DomSanitizer,
    protected $transitions: TransitionService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private router: UIRouter,
    private location: Location,
    private titleService: Title,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    // private slideshowService: SlideshowService,
    @Inject('Window') private window: any
  ) {
    if (whitelabel.leolux || whitelabel.press) {
      this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/styles_leolux.css');
    }

    if (whitelabel.lx || whitelabel.professional) {
      this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/styles_lx.css');
    }

    if (whitelabel.pode) {
      this.cssUrl =  this.sanitizer.bypassSecurityTrustResourceUrl('/styles_pode.css');
    }

    this.angulartics2GoogleTagManager.startTracking();

    // set a boolean which indicates authentication status for switching navigation files!
    this.isAuthenticated = this.authService.isAuthenticated();
    this.authService.authenticationStatus().subscribe(authenticationStatus => {
      this.isAuthenticated = authenticationStatus;
    });

    $transitions.onSuccess({}, () => {
      // Scroll window back to top position when changing state
      window.scroll(0, 0);
    });
  }

  public ngOnInit(): void {
    this.createOrgNav();
    this.handleNotifications();
    this.setBrowserTitle();
  }

  public ngAfterViewInit(): void {
    // Init foundation scripting
    this.window.templateScripting.init();
  }

  public switchOrg(id: string): void {
    if (id && id !== '') {
      this.router.stateService.go('catalog', {brand: id});
    }
  }

  private setBrowserTitle(): void {
    let _whitelabelTranslation = 'leolux';
    if (whitelabel.pode) {
      _whitelabelTranslation = 'pode';
    } else if (whitelabel.press) {
      _whitelabelTranslation = 'press';
    } else if (whitelabel.professional) {
      _whitelabelTranslation = 'prof';
    }

    this.translate.get(`browser-titles.${_whitelabelTranslation}`).subscribe((whitelabelTitle) => {
      this.titleService.setTitle(whitelabelTitle);
    });

    // Watch language changes for updating the browser title
    this.translate.onLangChange.subscribe(() => this.titleService.setTitle(this.translate.instant(`browser-titles.${_whitelabelTranslation}`)));
  }

  private createOrgNav(): void {
    this.orgNav = [
      {
        name: 'organisations.leolux.name',
        id: 'leolux'
      },
      {
        name: 'organisations.pode.name',
        id: 'pode'
      },
      {
        name: 'organisations.lx.name',
        id: 'lx'
      }
    ];
  }

  private handleNotifications() {
    this.notificationService.notification().subscribe((notification: Notification) => {
      if (notification instanceof CrudNotification) {

        // check for custom crud notification, or else, display the default notification
        if (this.customCrudNotificationMsg(notification) === false && notification.getEntity()) {
          this.translate.get(`entity.${(<any>notification.getEntity()).name.toLowerCase()}`).subscribe((entity) => {
            this.translate
              .get(`notification.${notification.getLevel()}.${notification.getAction()}`, {entity: entity})
              .subscribe((message) => {
                switch (notification.getLevel()) {
                  case NotificationLevel.SUCCESS: {

                    switch (notification.getAction()) {
                      case CrudAction.CREATE:
                      case CrudAction.UPDATE:
                      case CrudAction.DELETE: {
                        this.toastr[notification.getLevel()](message);
                      } break;
                    }

                  } break;

                  default: {
                    switch (notification.getHttpCode()) {
                      case 401: {
                        // do nothing, refresh token or redirect will already handle this
                      } break;

                      case 403: {
                        this.translate
                          .get(`notification.unauthorized.${notification.getAction()}`, {entity: entity.toLowerCase()})
                          .subscribe((unauthorizedMsg) => {
                            this.toastr[notification.getLevel()](unauthorizedMsg);
                          });
                      } break;

                      case 422: {
                        switch ((<any>notification.getEntity()).name) {

                          default: {
                            this.toastr[notification.getLevel()](message);
                          } break;
                        }
                      } break;

                      default: {
                        this.toastr[notification.getLevel()](message);
                      } break;
                    }
                  }
                }
            });
          });
        }
      } else {
        this.translate.get(notification.getData()).subscribe((translation: string) => {
          if (translation !== notification.getData()) {
            this.toastr[notification.getLevel()](translation);
          }
        });
      }
    });
  }

  private customCrudNotificationMsg(notification: CrudNotification): boolean {
    const rules: CustomNotificationRule[] = [
      {
        level: NotificationLevel.ERROR,
        action: [CrudAction.CREATE, CrudAction.UPDATE],
        entity: User,
        httpCode: 422,
        errors: [
          {param: 'email', msg: 'already in use'}
        ],
        translateKey: 'notification.custom.error.user.emailInUse'
      },
      {
        level: NotificationLevel.ERROR,
        action: [CrudAction.CREATE, CrudAction.UPDATE],
        entity: User,
        httpCode: 422,
        errors: [
          {param: 'username', msg: 'already in use'}
        ],
        translateKey: 'notification.custom.error.user.usernameInUse'
      }
    ];


    for (const rule of rules) {
      if (rule.level && rule.level !== notification.getLevel()) {
        continue;
      }

      if (rule.action) {
        rule.action = (rule.action instanceof Array) ? rule.action : [rule.action];
        if (rule.action.indexOf(notification.getAction()) === -1) {
          continue;
        }
      }

      if (rule.entity && rule.entity !== notification.getEntity()) {
        continue;
      }

      if (rule.httpCode && rule.httpCode !== notification.getHttpCode()) {
        continue;
      }

      if (rule.errors && notification.getData() && notification.getData().errors) {
        let specifiedErrorsFound = 0;
        for (let i = 0; i < rule.errors.length; i++) {
          for (const key in notification.getData().errors) {
            if (notification.getData().errors.hasOwnProperty(key)) {
              const error = notification.getData().errors[key];
              if (error.param === rule.errors[i].param && error.msg === rule.errors[i].msg) {
                specifiedErrorsFound++;
              }
            }
          }
        }

        if (specifiedErrorsFound !== rule.errors.length) {
          continue;
        }
      }

      // Still here? Show custom message!
      this.translate.get(rule.translateKey).subscribe((translation: string) => {
        this.toastr[notification.getLevel()](translation);
      });
      return true;
    }

    return false;
  }

  public doLogout(): void {
    this.authService.logout(true);
    // this.router.stateService.go('home');
    this.router.stateService.go('authentication.login');
  }
}
