import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { whitelabel } from '../../../whitelabels/whitelabel';

@Injectable()

export class StaticService {
  private unixTimeStamp: number = Math.round((new Date()).getTime() / 1000);

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  public getSlideshow(): Observable<any> {
    return this.http.get<any>(environment.slideshowUrl, {
      params: {
        'v': this.unixTimeStamp.toString()
      }
    });
  }

  public getHomepageLinks(): Observable<any> {
    let _lang = 'NL';
    if (this.translate.currentLang === 'en-US') {
      _lang = 'EN';
    } else {
      _lang = (this.translate.currentLang.indexOf('-') !== -1) ? this.translate.currentLang.substr(this.translate.currentLang.indexOf('-') + 1) : 'NL';
    }

    let _imanagerUrl = environment.imanagerUrl.leolux;
    if (whitelabel.pode) {
      _imanagerUrl = environment.imanagerUrl.pode;
    } else if (whitelabel.lx) {
      _imanagerUrl = environment.imanagerUrl.lx;
    } else if (whitelabel.press) {
      _imanagerUrl = environment.imanagerUrl.press;
    } else if (whitelabel.professional) {
      _imanagerUrl = environment.imanagerUrl.professional;
    }

    return this.http.get<any>(_imanagerUrl + '/mx' + _lang, {
      params: {
        'v': this.unixTimeStamp.toString()
      }
    });
  }
}
