export const environment = {
  production: true,
  imanagerUrl: {
    leolux: 'https://www.leolux.com/IManager/Content/88171/Ilxw/mt65721',
    pode: 'https://www.leolux.com/IManager/Content/88309/paZR/mt65775',
    lx: 'https://www.leolux.com/IManager/Content/88311/LZCC/mt65777',
    press: 'https://www.leolux.com/IManager/Content/88313/1TCa/mt65779',
    professional: 'https://www.leolux.com/IManager/Content/91483/EYqI/mt67173'
  },
  slideshowUrl: 'https://www.leolux.com/IManager/Content/89767/gOPq/mt46663/mp0',
  requestFormUrl: 'https://www.leolux.com/IManager/Content/93573/PDrC/mt68215',
  apiUrl: 'https://api-beeldbank-frontend.leolux.nl',
  domainGroups: [
    [
      { domain: 'images.leolux.com', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } },
      { domain: 'images.leolux.nl', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } },
      { domain: 'beeldenbank.leolux.nl', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } }],
    [{ domain: 'images.pode.eu', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } }],
    [{ domain: 'press.leolux.com', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } }],
    [{ domain: 'professional.leolux-lx.com', language: { visibleLanguages: ['en-US', 'nl-NL', 'fr-FR', 'de-DE'] } }],
  ]
};
